import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RegisterSection from "../components/members/register"
const RegisterPage = () => {
  return (
    <Layout>
      <SEO
        title="Registration - KYTS Members Registration"
        desc="Registration - KYTS Members Registration"
        keywords="KYTS Members Registration"
      />
      <RegisterSection />
    </Layout>
  )
}

export default RegisterPage
